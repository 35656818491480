import React, { useState, useContext } from 'react'
import { Route, Routes } from "react-router-dom";
import useAlert from '../../hooks/useAlert';
import Select from '../../components/doctor/select';
import Summary from '../../components/doctor/summary';
import Successful from '../../components/doctor/successful';
import { useFetchAPI } from '../../api/axios/axios';
import { AppContext } from '../../App';
import { convertToISOString } from '../../components/helperFunctions';

export const Doctors = () => {
    const data = localStorage.getItem('doctor');
    const doc = JSON.parse(data);
    const { displayAlert } = useAlert();
    const { CREATE_PAYMENT_URL, CREATE_CONSULTATION } = useFetchAPI();
    const { loading: appLoading, setLoading: setAppLoading } = useContext(AppContext);

    const [time, setTime] = useState('');
    const [channel, setChannel] = useState('');
    const [symptoms, setSymptoms] = useState('');
    const [level, setLevel] = useState('');
    const [expect, setExpect] = useState('');
    const [aob, setAob] = useState('');
    const [fee, setFee] = useState('');
    const [batches, setBatches] = useState([]);
    const [selectedDate, setSelectedDate] = useState('');
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phone, setPhone] = useState('');
    const [gender, setGender] = useState('');
    const [loading, setLoading] = useState(false);
    const providerId = localStorage.getItem("providerId");
    const isoString = convertToISOString(selectedDate, time);
    const callbackUrl = process.env.REACT_APP_FRONTEND_URL;

    
    const user = localStorage.getItem("profile") == 'undefined' ? '' : JSON.parse(localStorage.getItem("profile"));

    const hmoID = localStorage.getItem("hmoID") 

    const createConsultation = async () => {

        let uniqueProvider =
        process.env.REACT_APP_PROVIDER_KEY_Khairo === "66e000f0a983160013ca8d38"
          ? true
          : false;

        function convertTime(inputTime) {
            const [year, month, day] = inputTime.split('-'); // Split the input date into year, month, and day
            const hours = '00'; // Set hours to '00'
            const minutes = '00'; // Set minutes to '00'
        
            // Create a new date string in the desired format
            const dateString = `${year}-${month}-${day}T${hours}:${minutes}:00+01:00`;
        
            return dateString;
        } 
        
        const formattedTime = convertTime(selectedDate);
        
        

        try {

            setLoading(true);
            setAppLoading(true);
            const consultationVar = {
                consultationOwner: 'myself',
                patientData: {
                  email: user?.enrollee?.email ? user?.enrollee.email : email,
                  firstName: user?.enrollee?.name ? user?.enrollee.firstName : user.firstName,
                  lastName: user?.enrollee?.name ? user?.enrollee.lastName : user.lastName,
                  phoneNumber: user?.enrollee?.phone ? user?.enrollee.phone : user.phoneNumber,
                  gender: user?.gender,
                  ...(user?.enrollee?.hmoId && user.enrollee.hmoId !== "false"
                    ? { hmoId: user.enrollee.hmoId }
                    : hmoID && hmoID !== "false"
                    ? { hmoId: hmoID }
                    : {}),
                  height: "0",
                  weight: "0",
                },
                doctor: doc._id,
                discomfortLevel: level,
                description: aob == ""? "nil" :aob,
                firstNotice: uniqueProvider ? 'Nil' : expect,
                providerId: providerId,
                contactMedium: channel,
                time: `${selectedDate}T${time}:00+01:00`,
                symptoms:
                  batches.length > 0 && symptoms.length > 0
                    ? [...batches.map((batch) => ({ name: batch })), { name: symptoms }]
                    : batches.length > 0
                    ? batches.map((batch) => ({ name: batch }))
                    : symptoms.length > 0
                    ? [{ name: symptoms }]
                    : [],
                type: "scheduled",
                providerId: providerId,
                status: "pending",
                createdThrough: "doctor-direct",
              };
              
            const res = await CREATE_CONSULTATION(consultationVar);
            localStorage.setItem("consultationInfo", JSON.stringify(res?.data?.data));
            setLoading(false);
            const paymentUrlResponse = await CREATE_PAYMENT_URL({
                email: email,
                reason: "consultation",
                itemId: res?.data?.data?._id,
                callbackUrl: `${callbackUrl}doctors/consultsuccess`,
                amount: doc?.fee
            });
            const reference = paymentUrlResponse?.data?.data?.reference;
            const url = paymentUrlResponse?.data?.data?.authorization_url;
            localStorage.setItem("psRef", reference);
            window.open(url, '_self');
            setAppLoading(false);
        } catch (error) {
            displayAlert("error", "Initiating consultation failed!");
            console.error("Failed to create consultation", error);
            setAppLoading(false);   
            setLoading(false);   
        }
    }

    const handleReset = () => {
        setTime('');
        setChannel('');
        setBatches([]);
        setLevel('');
        setExpect('');
        setAob('');
        localStorage.clear();
        sessionStorage.clear();
    };
    
    return (
        <div className='bg-secondary h-full w-full mid:place-content-center flex bg-secondary_fix'>
            <Routes>
                <Route index element={
                    <Select 
                        doc={doc} 
                        handleReset={handleReset}
                        time={time}
                        channel={channel}
                        setTime={setTime}
                        setChannel={setChannel}
                        setSymptoms={setSymptoms}
                        setLevel={setLevel}
                        setExpect={setExpect}
                        setAob={setAob} 
                        symptoms={symptoms} 
                        batches={batches}
                        setBatches={setBatches}
                        aob={aob}
                        level={level} 
                        expect={expect} 
                        setFee={setFee}
                        setSelectedDate={setSelectedDate}
                        selectedDate={selectedDate}
                        setEmail={setEmail}
                        email={email}
                        setFirstName={setFirstName}
                        firstName={firstName}
                        setLastName={setLastName}
                        lastName={lastName}
                        setGender={setGender}
                        gender={gender}
                        phone={phone}
                        setPhone={setPhone}
                        createConsultation={createConsultation}

                    />
                } />
                <Route path="/consultsummary" element={
                    <Summary 
                        doc={doc}
                        selectedDate={selectedDate}
                        time={time}
                        channel={channel}
                        level={level} 
                        expect={expect} 
                        aob={aob}
                        batches={batches}
                        handleReset={handleReset}
                        fee={fee}
                        createConsultation={createConsultation}
                        loading={loading}
                    /> 
                } />
                <Route path="/consultsuccess" element={<Successful />} />
            </Routes>

            
        </div>
    )
}
